import React from 'react';
import { Select } from '@aurora/widgets-react';
import PropTypes from 'prop-types';

const CustomSelector = ({ className, header, list, selectedValue, handleChange }) => {
  return (
    <div className={className !== null ? className : ''}>
      <Select
        className="custom-select"
        onChange={handleChange}
        list={list}
        value={selectedValue}
        native
        label={header}
      />
    </div>
  );
};
CustomSelector.propTypes = {
  className: PropTypes.string,
  header: PropTypes.string,
  list: PropTypes.array,
  selectedValue: PropTypes.any,
  handleChange: PropTypes.func,
};
export default CustomSelector;
