const circular = () => {
  const seen = new WeakSet();
  return (key, value) => {
    if (key.startsWith('_')) return; // Don't compare React's internal props.
    if (typeof value === 'object' && value !== null) {
      if (seen.has(value)) return;
      seen.add(value);
    }
    return value;
  };
};

export function areChildrenEqual(prevChildren, nextChildren) {
  return (
    JSON.stringify(prevChildren, circular()) ===
    JSON.stringify(nextChildren, circular())
  );
}
