export function addSubstanceKeyValues(substanceKeyValues, substances) {
  substances.forEach((substance) => {
    addSubstanceKeyValue(substanceKeyValues, substance);
  });
}

export function addSubstanceKeyValue(substanceKeyValues, substance) {
  const longName = substance[Object.keys(substance)].long_name;
  if (substanceKeyValues.some((x) => x.value === longName)) return;
  substanceKeyValues.push({
    key: Object.keys(substance)[0],
    value: longName,
  });
}

export function addSpecialSubstanceKeyValues(substanceKeyValues) {
  if (hasAnyGreenGasSubstance(substanceKeyValues))
    substanceKeyValues.push(getGreenGasTotalKeyValuePair());
  substanceKeyValues.push(getDisabledKeyValuePair());
}

function hasAnyGreenGasSubstance(substanceKeyValues) {
  const ggtSlugs = ['CH4', 'CO2', 'N2O', 'SF6', 'PFC', 'HFC'];
  return substanceKeyValues.some((item) => ggtSlugs.indexOf(item.key) >= 0);
}

export function sortSubstances(items) {
  const excludedSort = ['NH3', 'NMVOC', 'NOx', 'PM25', 'SOx', 'GGT', ''];
  items.sort((a, b) => {
    const aIndex = excludedSort.indexOf(a.key);
    const bIndex = excludedSort.indexOf(b.key);
    if (aIndex < 0 && bIndex < 0) return valueComparer(a, b);
    if (aIndex < 0 || aIndex < bIndex) return 1;
    if (bIndex < 0) return -1;
    return 0;
  });
}

export function sortKeyValuePairs(items) {
  items.sort((a, b) => valueComparer(a, b));
}

function valueComparer(a, b) {
  return a.value.localeCompare(b.value, 'sv', { sensitivity: 'case' });
}

export function getGreenGasTotalKeyValuePair() {
  return { key: 'GGT', value: 'Växthusgaser totalt' };
}

function getDisabledKeyValuePair() {
  return { key: '', value: '', disabled: true };
}

export function getAllKeyValuePair() {
  return { key: 0, value: 'Alla' };
}

export function setDefaultSelectionIfSelectedSubstanceIsMissing(
  substanceKeyValues,
  selectedSubstance,
  setSelectedSubstance
) {
  if (isSubstanceAvailable(substanceKeyValues, selectedSubstance.id) === false) {
    const ggt = getGreenGasTotalKeyValuePair();
    if (isSubstanceAvailable(substanceKeyValues, ggt.key))
      setSelectedSubstance(keyValuePairAsIdName(ggt));
    else {
      const firstSubstance = substanceKeyValues[0];
      setSelectedSubstance(keyValuePairAsIdName(firstSubstance));
    }
  }
}

function isSubstanceAvailable(substanceKeyValues, substanceSlug) {
  return substanceKeyValues.some((x) => x.key === substanceSlug);
}

export function keyValuePairAsIdName(keyValuePair) {
  return {
    id: keyValuePair.key,
    name: keyValuePair.value,
  };
}
