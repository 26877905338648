import React, { useState } from 'react';
import './App.css';
import TopBar from '../TopBar';
import FilterBar from '../FilterBar';
import AirMap from '../AirMap';
import TimeSlider from '../TimeSlider';
import Diagram from '../Diagram';
import { usePrevious } from '../../hooks/usePrevious';

const AppRoot = () => {
  const [years, setYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState(0);
  const [selectedCounty, setSelectedCounty] = useAllState();
  const [selectedMunicipality, setSelectedMunicipality] = useAllState();
  const prevSelectedCounty = usePrevious(selectedCounty);
  const prevSelectedMunicipality = usePrevious(selectedMunicipality);
  const [selectedSector, setSelectedSector] = useAllState();
  const [selectedSubSector, setSelectedSubSector] = useAllState();
  const [selectedSubstance, setSelectedSubstance] = useState({
    id: 'GGT',
    name: 'Växthusgaser totalt',
  });

  function useAllState() {
    return useState({ id: 0, name: 'Alla' });
  }

  function handleChangeYears(yearsArray) {
    setYears(yearsArray);
    if (yearsArray.indexOf(selectedYear) < 0)
      setSelectedYear(yearsArray[yearsArray.length - 1]);
  }

  return (
    <div className="App">
      <TopBar />
      <FilterBar
        prevSelectedCounty={prevSelectedCounty}
        selectedCounty={selectedCounty}
        selectedMunicipality={selectedMunicipality}
        selectedSector={selectedSector}
        selectedSubSector={selectedSubSector}
        selectedSubstance={selectedSubstance}
        setSelectedCounty={setSelectedCounty}
        setSelectedMunicipality={setSelectedMunicipality}
        setSelectedSector={setSelectedSector}
        setSelectedSubSector={setSelectedSubSector}
        setSelectedSubstance={setSelectedSubstance}
      />
      <div className="app-container">
        <div className="app-container-left">
          <AirMap
            prevSelectedCounty={prevSelectedCounty}
            prevSelectedMunicipality={prevSelectedMunicipality}
            selectedCounty={selectedCounty}
            selectedMunicipality={selectedMunicipality}
            selectedSector={selectedSector}
            selectedSubSector={selectedSubSector}
            selectedSubstance={selectedSubstance}
            selectedYear={selectedYear}
          />
          <TimeSlider onChange={setSelectedYear} year={selectedYear} years={years} />
        </div>
        <div className="app-container-right">
          <Diagram
            selectedCounty={selectedCounty}
            selectedMunicipality={selectedMunicipality}
            selectedSector={selectedSector}
            selectedSubSector={selectedSubSector}
            selectedSubstance={selectedSubstance}
            onChangeYears={handleChangeYears}
          />
        </div>
      </div>
    </div>
  );
};

export default AppRoot;
