import React from 'react';
import { Row, Col, H4 } from '@aurora/widgets-react';
import PropTypes from 'prop-types';
import './Loading.css';

const Loading = ({ isLoading, text }) => {
  if (!isLoading) {
    return null;
  }
  return (
    <Row>
      <Col className="loading-container" xs={12} m={12}>
        <H4>{text || 'Laddar data...'}</H4>
      </Col>
    </Row>
  );
};

Loading.propTypes = {
  isLoading: PropTypes.bool,
  text: PropTypes.string,
};

export default Loading;
