import React from 'react';
import { Button } from '@aurora/widgets-react';
import { DownloadOutline } from '@aurora/icons-react';
import PropTypes from 'prop-types';
import './DownloadButtons.css';

const DownloadButtons = ({ downloadPdf, downloadTiff, downloadAscii, queryString }) => {
  return (
    <div className="download-buttons-container">
      <Button
        onClick={downloadPdf}
        variant={Button.variants.secondary}
        icon={<DownloadOutline />}
        title="Ladda ner pdf"
        size="s"
      >
        PDF
      </Button>
      <Button
        onClick={() => downloadTiff(queryString)}
        variant={Button.variants.secondary}
        icon={<DownloadOutline />}
        title="Ladda ner tiff"
        size="s"
      >
        TIFF
      </Button>
      <Button
        onClick={() => downloadAscii(queryString)}
        variant={Button.variants.secondary}
        icon={<DownloadOutline />}
        title="Ladda ner ascii"
        size="s"
      >
        ASCII
      </Button>
    </div>
  );
};

DownloadButtons.propTypes = {
  downloadPdf: PropTypes.func,
  downloadTiff: PropTypes.func,
  downloadAscii: PropTypes.func,
  queryString: PropTypes.string,
};

export default DownloadButtons;
