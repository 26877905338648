import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Control from 'react-leaflet-control';
import { Button } from '@aurora/widgets-react';
import { areChildrenEqual } from '../../utils/areChildrenEqual';
import './MapDownload.css';
import { HideOutline, DownloadOutline } from '@aurora/icons-react';
import { useOutsideClick } from '../../hooks/useOutsideClick';

const MapDownload = ({ enabled, iconText, hideText, children }) => {
  const [open, setOpen] = useState(false);

  const toggleControl = () => {
    if (!enabled) return;
    setOpen((open) => !open);
  };

  const control = useRef(null);
  useOutsideClick(control, toggleControl);

  return (
    <>
      <Control
        id="map-download"
        position="bottomleft"
        className="map-download--control"
      >
        <Button
          disabled={!enabled}
          onClick={toggleControl}
          tabIndex={0}
          variant={Button.variants.secondary}
        >
          {open ? (
            <>
              <HideOutline />
              {hideText}
            </>
          ) : (
            <>
              <DownloadOutline />
              {iconText}
            </>
          )}
        </Button>
        {open && (
          <div ref={control} className="map-download-control--content">
            <div>{enabled && children}</div>
          </div>
        )}
      </Control>
    </>
  );
};

MapDownload.propTypes = {
  enabled: PropTypes.bool,
  iconText: PropTypes.string,
  hideText: PropTypes.string,
  children: PropTypes.node,
};

function areEqual(prevProps, nextProps) {
  return (
    prevProps.enabled === nextProps.enabled &&
    areChildrenEqual(prevProps.children, nextProps.children)
  );
}

export default React.memo(MapDownload, areEqual);
