import { appendSubmissionParam, appendQueryObject } from './utils';

const ERROR_MESSAGE = 'Ett fel uppstod att hämta data, vänligen försök igen senare.';

const END_POINT = `${process.env.REACT_APP_GEOFORD_API_URL}/api/getdata/`;

export const fetchData = async (queryObject) => {
  try {
    const url = appendSubmissionParam(appendQueryObject(END_POINT, queryObject));
    const response = await fetch(url);
    const json = await response.json();

    if (!response.ok) {
      return {
        error: ERROR_MESSAGE,
      };
    }

    return json;
  } catch (e) {
    return { error: ERROR_MESSAGE };
  }
};
