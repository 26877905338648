import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import * as L from 'leaflet';
import proj4leaflet from 'proj4leaflet';
L.extend(proj4leaflet);

const Proj4ImageOverlay = ({ map, url, bounds, ...props }) => {
  const imageLayer = useRef(null);

  useEffect(() => {
    imageLayer.current = L.Proj.imageOverlay(url, bounds, props);
    imageLayer.current.addTo(map);

    return () => {
      if (imageLayer.current) {
        map.removeLayer(imageLayer.current);
        imageLayer.current = null;
      }
    };
  }, [map, url, bounds, props]);

  return null;
};

Proj4ImageOverlay.propTypes = {
  map: PropTypes.any,
  ref: PropTypes.object,
  url: PropTypes.string,
  bounds: PropTypes.object,
};

function areEqual(prevProps, nextProps) {
  return prevProps.url === nextProps.url && prevProps.bounds === nextProps.bounds;
}

export default React.memo(Proj4ImageOverlay, areEqual);
