import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Button, SubText } from '@aurora/widgets-react';
import {
  ChevronLeftOutline,
  ChevronRightOutline,
  PlayOutline,
  PauseOutline,
} from '@aurora/icons-react';
import './NumberSlider.css';

const NumberSlider = ({
  valueLabel,
  minLabel,
  maxLabel,
  unitLabel,
  value,
  min,
  max,
  numbers,
  disabled,
  onChange,
}) => {
  const [playing, setPlaying] = useState(false);
  const playTimeout = useRef(null);

  useEffect(() => {
    return () => {
      // Clear play timeout when component unmounts
      clearTimeout(playTimeout.current);
    };
  }, []);

  if (playing) {
    const currentIndex = numbers.indexOf(value);
    const index = value < max ? currentIndex + 1 : numbers.indexOf(min);
    playTimeout.current = setTimeout(() => {
      onChange(numbers[index]);
    }, 2000);
  }

  const next = (e) => {
    e.stopPropagation();
    if (value < max) {
      const index = numbers.indexOf(value);
      onChange(numbers[index + 1]);
    }
  };

  const prev = (e) => {
    e.stopPropagation();
    if (value > min) {
      const index = numbers.indexOf(value);
      onChange(numbers[index - 1]);
    }
  };

  const play = () => {
    const currentIndex = numbers.indexOf(value);
    const index = value < max ? currentIndex + 1 : numbers.indexOf(min);
    onChange(numbers[index]);
    setPlaying(true);
  };

  const pause = () => {
    setPlaying(false);
    clearTimeout(playTimeout.current);
  };

  const handleChange = (e) => {
    const { value } = e.target;
    if (!isNaN(Number(value))) {
      onChange(Number(value));
    }
  };

  return (
    <div className="number-slider-container">
      <div className="number-slider">
        <div className="number-number-slider-buttons">
          <Button
            title="left"
            className="number-slider-button"
            variant={Button.variants.tertiary}
            shape={Button.shapes.round}
            icon={<ChevronLeftOutline />}
            onClick={prev}
            disabled={disabled || value === min}
            iconOnly
            size="m"
          />
          <Button
            title="pause"
            className="number-slider-button"
            variant={Button.variants.secondary}
            shape={Button.shapes.round}
            icon={!playing ? <PlayOutline /> : <PauseOutline />}
            onClick={!playing ? play : pause}
            disabled={disabled}
            iconOnly
            size="m"
          />
          <Button
            title="right"
            className="number-slider-button"
            variant={Button.variants.tertiary}
            shape={Button.shapes.round}
            icon={<ChevronRightOutline />}
            onClick={next}
            disabled={disabled || value === max}
            iconOnly
            size="m"
          />
        </div>
        <div className="number-slider-control">
          <SubText className="number-slider-value">
            {disabled ? '' : valueLabel}
          </SubText>
          <div className="number-slider-range-container-outer">
            <div className="number-slider-range-container-inner">
              <input
                title="number-slider-range"
                className="number-slider-range"
                type="range"
                value={value}
                min={min}
                max={max}
                step="1"
                onChange={handleChange}
                disabled={disabled || playing}
                list="steplist"
              />
              {!disabled && (
                <>
                  <datalist id="steplist">
                    {
                      // By not adding the actual numbers in the option
                      // elements I avoid using the default tick marks.
                      // Instead I use CSS to format the datalist and
                      // option elements to simulate tick marks. The
                      // reason for this is that Firefox currently don't
                      // support the default tick marks.
                      numbers.map((number) => (
                        <option key={number}></option>
                      ))
                    }
                  </datalist>
                  <SubText className="number-slider-min">{minLabel}</SubText>
                  <SubText className="number-slider-max">{maxLabel}</SubText>
                </>
              )}
            </div>
            {!disabled && <SubText className="number-slider-unit">{unitLabel}</SubText>}
          </div>
        </div>
      </div>
    </div>
  );
};

NumberSlider.propTypes = {
  valueLabel: PropTypes.string,
  minLabel: PropTypes.string,
  maxLabel: PropTypes.string,
  unitLabel: PropTypes.string,
  value: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  numbers: PropTypes.array,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

export default NumberSlider;
