import React from 'react';
import NumberSlider from '../NumberSlider';
import PropTypes from 'prop-types';

const TimeSlider = ({ onChange, year, years }) => {
  // Since some years might be missing we cannot use the years-array in
  // the NumberSlider component. Instead we use an array with indexes for
  // each year available in the years-array.
  const indexes = range(years.length, 0);
  const minIndex = 0;
  const maxIndex = indexes.length - 1;

  function range(size, startAt = 0) {
    return [...Array(size).keys()].map((i) => i + startAt);
  }

  function getCurrentIndex() {
    return years.indexOf(year);
  }

  function getValueLabel() {
    if (year === undefined) return '';
    return year.toString();
  }

  function getMinLabel() {
    if (years.length === 0) return '';
    return years[minIndex].toString();
  }

  function getMaxLabel() {
    if (years.length === 0) return '';
    return years[maxIndex].toString();
  }

  function handleChange(selectedIndex) {
    onChange(years[selectedIndex]);
  }

  return (
    <NumberSlider
      valueLabel={getValueLabel()}
      minLabel={getMinLabel()}
      maxLabel={getMaxLabel()}
      unitLabel="År"
      value={getCurrentIndex()}
      min={minIndex}
      max={maxIndex}
      onChange={handleChange}
      numbers={indexes}
      disabled={years.length === 0}
    />
  );
};

TimeSlider.propTypes = {
  onChange: PropTypes.func,
  year: PropTypes.number,
  years: PropTypes.array,
};

export default TimeSlider;
