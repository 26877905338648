import React, { useEffect, useState } from 'react';
import { Checkbox } from '@aurora/widgets-react';
import { fetchData } from '../../api/chart';
import Error from '../Error';
import Loading from '../Loading';
import PropTypes from 'prop-types';
import './Diagram.css';
import { Chart, Line, defaults } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import LEGEND from './legendColors.json';

export const Diagram = ({
  selectedCounty,
  selectedMunicipality,
  selectedSector,
  selectedSubSector,
  selectedSubstance,
  onChangeYears,
}) => {
  const [options, setOptions] = useState({});
  const [data, setData] = useState({});
  const [primarySubmission, setPrimarySubmission] = useState(0);
  const [secondarySubmissions, setSecondarySubmissions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showHelpTexts, setShowHelpTexts] = useState(false);
  const [selectAll, setSelectAll] = useState(true);

  Chart.plugins.register(ChartDataLabels);
  const ref = React.createRef();

  function formatTooltipNumber(tooltip, data) {
    const label = getTooltipLabel(tooltip, data);
    const value = getTooltipValue(tooltip, data);
    const rounded = round(value, getPrecision(data));
    const stringValue = addThousandSeparators(rounded);
    return ` ${label}: ${stringValue}`;
  }

  function getTooltipLabel(tooltip, data) {
    return data.datasets[tooltip.datasetIndex].label;
  }

  function getTooltipValue(tooltip, data) {
    return data.datasets[tooltip.datasetIndex].data[tooltip.index];
  }

  function getPrecision(data) {
    const max = getMaxValue(data);
    return getDecimals(max);
  }

  function getMaxValue(data) {
    let max = 0;
    data.datasets.forEach((ds) => {
      max = Math.max(max, ...ds.data);
    });
    return max;
  }

  function addThousandSeparators(value) {
    return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');
  }

  function getDecimals(value) {
    const significantDecimals = 4;
    const minDecimals = 0;
    const log10 = Math.round(Math.log10(value));
    return Math.max(minDecimals, -log10 + significantDecimals - 1);
  }

  function round(value, decimals) {
    const roundingFactor = Math.pow(10, decimals);
    return Math.round(value * roundingFactor) / roundingFactor;
  }

  function repeat(value, occasions) {
    return Array(occasions).fill(value);
  }

  function onShowHelpTextsChange(e) {
    let value = e.target.checked;
    let chartRef = ref.current.chartInstance;
    chartRef.options.plugins.datalabels.display = value;
    setShowHelpTexts(value);
    setOptions(chartRef.options);

    // By redrawing the chart only I avoid refetching and rebinding all
    // chart data, which I otherwise would have done by adding
    // showHelpTexts to the list of state variables which should call
    // useEffect().
    chartRef.update();
  }

  function onSelectAllChange(e) {
    let value = e.target.checked;
    let chartRef = ref.current.chartInstance;
    chartRef.data.datasets.forEach((dataset) => {
      if (dataset.enabledLegendItem) {
        Object.keys(dataset._meta).forEach((key) => {
          dataset._meta[key].hidden = !value;
        });
      }
    });
    setSelectAll(value);
    setOptions(chartRef.options);
    chartRef.update();
  }

  function onLegendItemClick(e, legendItem) {
    const index = legendItem.datasetIndex;
    const chartRef = this.chart;
    const dataset = chartRef.data.datasets[index];
    if (dataset.enabledLegendItem) {
      const meta = chartRef.getDatasetMeta(index);
      meta.hidden = meta.hidden === null ? !dataset.hidden : null;
      chartRef.update();
      const anyHiddenItems = this.legendItems.some((item) => item.hidden);
      setSelectAll(!anyHiddenItems);
    }
  }

  function initLineColors(sectorKeys, submissions) {
    if (submissions.length === 1) {
      return LEGEND;
    } else {
      let lineColors = [];
      sectorKeys.forEach((key, idx) => {
        const submissionStart = key.indexOf('[');
        const sectorName = key.substring(0, submissionStart);
        const firstKeyIndex = Math.min(
          LEGEND.length - 1,
          sectorKeys.findIndex((key) => key.startsWith(sectorName))
        );
        lineColors[idx] = LEGEND[firstKeyIndex];
      });
      return lineColors;
    }
  }

  function getLineColors(lineColors, idx, show) {
    return show ? lineColors[idx].color : 'transparent';
  }

  function initLineStyles(sectorKeys, submissions) {
    if (submissions.length === 1) {
      return repeat([], sectorKeys.length);
    } else {
      let lineStyles = [];
      sectorKeys.forEach((key, idx) => {
        const submissionStart = key.indexOf('[');
        const submission = parseInt(key.substring(submissionStart + 1, key.length));
        const submissionIndex = submissions.indexOf(submission);
        lineStyles[idx] = [3 * submissionIndex, 3 * submissionIndex];
      });
      return lineStyles;
    }
  }

  function getLegendPadding(length) {
    if (length > 28) return 5;
    else if (length > 22) return 10;
    else return 15;
  }

  function hasValues(sectorValues) {
    return sectorValues.some((sectorValue) => sectorValue.value !== 0.0);
  }

  function getHelpTextColors(lineColor, count) {
    const labelColors = repeat('transparent', count);
    const middlePoint = Math.floor(count / 2);
    labelColors[middlePoint] = lineColor;
    return labelColors;
  }

  function addMissingYearValueObject(xAxisYears, array) {
    if (!xAxisYears || !array) return;
    xAxisYears.map((x) => {
      const yearIsMissing = !array.map((x) => x.year).includes(x);
      if (yearIsMissing) {
        array.push({ year: x, value: null });
      }
    });
  }

  function sortSectorByYearAsc(array) {
    if (!array) return;
    array.sort((b, a) => {
      return new Date(b.year) - new Date(a.year);
    });
  }

  function getDistinctYears(sectors) {
    const firstSector = sectors[Object.keys(sectors)[0]];
    const years = firstSector.map((sectorValue) => parseInt(sectorValue.year));
    return years;
  }

  function getRangeYears(distinctYears) {
    const minYear = Math.min(...distinctYears);
    const maxYear = Math.max(...distinctYears);
    const years = range(maxYear - minYear + 1, minYear);
    return years.map((year) => year.toString());
  }

  function range(size, startAt = 0) {
    return [...Array(size).keys()].map((i) => i + startAt);
  }

  function showEverySecondLabel(data) {
    let xLabels = data.ticks;
    xLabels.forEach((_labels, i) => {
      if (i % 2 == 1) {
        xLabels[i] = '';
      }
    });
  }

  function getHeaderText() {
    return `${getSelectedRegion().name} - ${selectedSector.name} - ${
      selectedSubSector.name
    } - ${selectedSubstance.name}`;
  }

  function getSelectedRegion() {
    return selectedMunicipality.id === 0 ? selectedCounty : selectedMunicipality;
  }

  function getSecondarySubmissions() {
    return secondarySubmissions.join(', ').replace(/,([^,]*)$/, ' och $1');
  }

  useEffect(() => {
    const initData = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await fetchData({
          county: selectedCounty.id,
          municipality: selectedMunicipality.id,
          hsektor: selectedSector.id,
          usektor: selectedSubSector.id,
          sub: selectedSubstance.id,
        });
        if (!response || response.error) {
          throw new Error('Fetching data failed...');
        }
        setPrimarySubmission(response.primary_submission);
        setSecondarySubmissions(response.secondary_submissions);
        const sectors = response.data[selectedSubstance.name];
        const distinctYears = getDistinctYears(sectors);
        onChangeYears(distinctYears); // eslint-disable-line
        const xAxisYears = getRangeYears(distinctYears);
        const dataObject = {
          labels: xAxisYears,
          datasets: [],
        };
        const submissions = [
          response.primary_submission,
          ...response.secondary_submissions,
        ];
        const sectorKeys = Object.keys(sectors);
        const lineColors = initLineColors(sectorKeys, submissions);
        const lineStyles = initLineStyles(sectorKeys, submissions);
        sectorKeys.forEach((sectorKey, idx) => {
          const sectorValues = sectors[sectorKey];
          addMissingYearValueObject(xAxisYears, sectorValues);
          sortSectorByYearAsc(sectorValues);
          const showLine = hasValues(sectorValues);
          const count = sectorValues.length;
          const lineColor = getLineColors(lineColors, idx, showLine);
          const lineStyle = lineStyles[idx];
          const pointColors = repeat(lineColor, count);
          const datalabelColors = getHelpTextColors(lineColor, count);
          dataObject.datasets.push({
            enabledLegendItem: showLine,
            hidden: !showLine,
            label: sectorKey,
            lineTension: 0,
            data: sectorValues.map((x) => x.value),
            backgroundColor: ['rgba(255, 255, 255, 0)'],
            borderColor: lineColor,
            borderDash: lineStyle,
            pointBackgroundColor: pointColors,
            pointBorderColor: pointColors,
            datalabels: {
              color: datalabelColors,
              align: 'top',
              formatter: function () {
                return sectorKey;
              },
            },
          });
        });

        defaults.global.defaultFontColor = 'black';
        const optionsObject = {
          maintainAspectRatio: false,
          title: {
            display: true,
            text: getHeaderText(),
            fontSize: 22,
          },
          legend: {
            display: true,
            position: 'right',
            labels: {
              usePointStyle: submissions.length === 1,
              boxWidth: submissions.length === 1 ? 40 : 19,
              padding: getLegendPadding(dataObject.datasets.length),
            },
            onClick: onLegendItemClick,
          },
          tooltips: {
            /*
            // This would be a way to generate a custom tooltip. Hopefully we won't have to.
            enabled: false,
            custom: function (tooltipModel) {
              let tooltipEl = document.getElementById('chartjs-tooltip');

              if (!tooltipEl) {
                tooltipEl = document.createElement('div');
                tooltipEl.id = 'chartjs-tooltip';
                document.body.appendChild(tooltipEl);
              }

              if (tooltipModel.opacity === 0) {
                tooltipEl.style.opacity = 0;
                return;
              }

              if (tooltipModel.body) {
                let titleLines = tooltipModel.title || [];
                let bodyLines = tooltipModel.body.map((bodyItem) => bodyItem.lines);

                let innerHtml = '';
                titleLines.forEach((title) => {
                  innerHtml += `<div>${title}</div>`;
                });
                bodyLines.forEach((body) => {
                  innerHtml += `<div>${body}</div>`;
                });
                tooltipEl.innerHTML = innerHtml;
              }

              let position = this._chart.canvas.getBoundingClientRect();

              let modX = 0;
              if (tooltipModel.xAlign === 'center') modX = tooltipModel.width / 2;
              else if (tooltipModel.xAlign === 'right') modX = tooltipModel.width;

              let modY = 0;
              if (tooltipModel.yAlign === 'center') modY = tooltipModel.height / 2;
              else if (tooltipModel.yAlign === 'above') modY = tooltipModel.height;

              tooltipEl.style.left = `${
                position.left + window.pageXOffset + tooltipModel.caretX - modX
              }px`;
              tooltipEl.style.top = `${
                position.top + window.pageYOffset + tooltipModel.caretY - modY
              }px`;
              tooltipEl.style.opacity = 1;
              tooltipEl.style.position = 'absolute';
            },
            */

            enabled: true,
            callbacks: {
              label: formatTooltipNumber,
            },
            backgroundColor: 'rgba(224, 224, 224, 0.9)',
            titleFontColor: 'black',
            titleFontSize: 16,
            bodyFontColor: 'black',
            bodyFontSize: 16,
            bodyFontStyle: 'bold',
            borderWidth: 1,
            borderColor: 'black',
          },
          spanGaps: true,
          scales: {
            xAxes: [
              {
                afterTickToLabelConversion: showEverySecondLabel,
                scaleLabel: {
                  display: true,
                  labelString: 'År',
                },
              },
            ],
            yAxes: [
              {
                ticks: {
                  precision: getPrecision(dataObject),
                  callback: addThousandSeparators,
                  beginAtZero: true,
                },
                scaleLabel: {
                  display: true,
                  labelString: 'ton/år',
                },
              },
            ],
          },
          plugins: {
            datalabels: {
              display: showHelpTexts,
            },
          },
        };
        setData(dataObject);
        setOptions(optionsObject);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        setError('Hämtningen av diagramdata misslyckades');
      }
    };

    initData();
    // To disable the warning about calling onChangeYears().
    // eslint-disable-next-line
  }, [
    selectedCounty,
    selectedMunicipality,
    selectedSubSector,
    selectedSector,
    selectedSubstance,
  ]);

  return (
    <>
      <Loading isLoading={loading} text="Laddar data..." />
      <Error
        error={
          !loading && !error && data.length === 0 ? 'Ingen data tillgänglig' : error
        }
      />
      {!loading && !error && data && (
        <>
          <Line data={data} options={options} plugins={[ChartDataLabels]} ref={ref} />
          <div className="chart-controls">
            <div className="chart-info-box">
              <div>
                OBS! Diagrammet visar interpolerade värden för mellanliggande år. Alla
                utsläpp av CO<sub>2</sub> avser fossil förbränning. De visade siffrorna
                är konsistenta med Naturvårdsverkets nationella statistik, förutom vad
                gäller utrikes transporter. Se metodbeskrivning för närmare förklaring.
              </div>
              <div>
                Om den valda sektorn saknar utsläpp av tidigare valt ämne kommer ett
                ämne aktuellt för den valda sektorn istället väljas och visas.
              </div>
              <div>Sidan visar data från submission {primarySubmission}.</div>
              {secondarySubmissions.length > 0 && (
                <div>
                  Diagrammet visar även data från submission {getSecondarySubmissions()}
                  .
                </div>
              )}
            </div>
            <div className="chart-checkbox-container">
              <Checkbox
                label="Visa hjälptexter"
                checked={showHelpTexts}
                onChange={onShowHelpTextsChange}
                className="chart-checkbox"
              />
            </div>
            <div className="chart-checkbox-container">
              <Checkbox
                label="Markera alla"
                checked={selectAll}
                onChange={onSelectAllChange}
                className="chart-checkbox"
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};
Diagram.propTypes = {
  selectedCounty: PropTypes.object,
  selectedMunicipality: PropTypes.object,
  selectedSubSector: PropTypes.object,
  selectedSector: PropTypes.object,
  selectedSubstance: PropTypes.object,
  onChangeYears: PropTypes.func,
};
export default Diagram;
