import { useEffect } from 'react';

export const useOutsideClick = (ref, effect) => {
  useEffect(() => {
    let mouseDownOutside = false;

    const handleMouseDown = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        mouseDownOutside = true;
      } else {
        mouseDownOutside = false;
      }
    };

    const handleMouseUp = (e) => {
      // Only perform effect if click starts AND ends outside
      if (mouseDownOutside && ref.current && !ref.current.contains(e.target)) {
        effect();
      }
    };

    document.addEventListener('click', handleMouseDown);
    document.addEventListener('mouseup', handleMouseUp);

    return () => {
      document.removeEventListener('click', handleMouseDown);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [ref, effect]);
};
