import React, { useState, useEffect } from 'react';
import { DownloadOutline } from '@aurora/icons-react';
import { Button } from '@aurora/widgets-react';
import './FilterBar.css';
import CountySelector from './CountySelector';
import SubstanceSelector from './SubstanceSelector';
import SectorSelector from './SectorSelector';
import MunicipalitySelector from './MunicipalitySelector';
import SubSectorSelector from './SubSectorSelector';
import PropTypes from 'prop-types';
import Error from '../Error';
import Loading from '../Loading';
import { getFilterOptions } from '../../api/filter';
import { getExcelFile } from '../../api/getexcelfile';

const FilterBar = ({
  prevSelectedCounty,
  selectedCounty,
  selectedMunicipality,
  selectedSector,
  selectedSubSector,
  selectedSubstance,
  setSelectedCounty,
  setSelectedMunicipality,
  setSelectedSector,
  setSelectedSubSector,
  setSelectedSubstance,
}) => {
  const [subSectors, setSubsectors] = useState([
    {
      key: 0,
      value: 'Alla',
    },
  ]);
  const [substances, setSubstances] = useState([
    {
      key: 0,
      value: 'Växthusgaser totalt',
    },
  ]);
  const [filter, setFilter] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const onFilterChange = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await getFilterOptions();
        if (!response || response.error) {
          throw new Error('Fetching data failed...');
        }
        setFilter(response);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        setError('Hämtningen av data misslyckades');
      }
    };
    onFilterChange();
  }, []);

  function onClickExcel() {
    getExcelFile({
      county: selectedCounty.id,
      municipality: selectedMunicipality.id,
      sub: selectedSubstance.id,
    });
  }

  return (
    <div className="filter-bar">
      <Loading isLoading={loading} text="Laddar stationsdata..." />
      <Error error={!loading && error ? 'Ingen data tillgänglig' : error} />
      {!loading && !error && (
        <>
          <CountySelector
            selectedCounty={selectedCounty}
            setSelectedCounty={setSelectedCounty}
            setSelectedMunicipality={setSelectedMunicipality}
            prevSelectedCounty={prevSelectedCounty}
            className="filter-bar-select"
          />
          <MunicipalitySelector
            selectedCounty={selectedCounty}
            selectedMunicipality={selectedMunicipality}
            setSelectedMunicipality={setSelectedMunicipality}
            className="filter-bar-select"
          />
          <SectorSelector
            filter={filter}
            selectedSubSector={selectedSubSector}
            setSubsectors={setSubsectors}
            selectedSector={selectedSector}
            setSelectedSector={setSelectedSector}
            setSubstances={setSubstances}
            setSelectedSubSector={setSelectedSubSector}
            selectedSubstance={selectedSubstance}
            setSelectedSubstance={setSelectedSubstance}
            className="filter-bar-select"
          />
          <SubSectorSelector
            filter={filter}
            setSubstances={setSubstances}
            selectedSubSector={selectedSubSector}
            setSelectedSubSector={setSelectedSubSector}
            subSectors={subSectors}
            selectedSector={selectedSector}
            selectedSubstance={selectedSubstance}
            setSelectedSubstance={setSelectedSubstance}
            className="filter-bar-select"
          />
          <SubstanceSelector
            filter={filter}
            substances={substances}
            setSelectedSubstance={setSelectedSubstance}
            selectedSubstance={selectedSubstance}
            className="filter-bar-select"
          />
          <Button
            onClick={onClickExcel}
            variant={Button.variants.secondary}
            icon={<DownloadOutline />}
            className="filter-bar-button"
          >
            Excel
          </Button>
        </>
      )}
    </div>
  );
};

FilterBar.propTypes = {
  prevSelectedCounty: PropTypes.object,
  selectedCounty: PropTypes.object,
  selectedMunicipality: PropTypes.object,
  selectedSector: PropTypes.object,
  selectedSubSector: PropTypes.object,
  selectedSubstance: PropTypes.object,
  setSelectedCounty: PropTypes.func,
  setSelectedMunicipality: PropTypes.func,
  setSelectedSector: PropTypes.func,
  setSelectedSubSector: PropTypes.func,
  setSelectedSubstance: PropTypes.func,
};
export default FilterBar;
