import React from 'react';
import PropTypes from 'prop-types';
import CustomSelector from './CustomSelector';
import { keyValuePairAsIdName } from './utils';

const SubstanceSelector = ({
  className,
  substances,
  setSelectedSubstance,
  selectedSubstance,
}) => {
  const handleChange = (e) => {
    const newValue = e.target.value;
    const currentSubstance = substances.find((obj) => {
      return obj.key === newValue;
    });
    setSelectedSubstance(keyValuePairAsIdName(currentSubstance));
  };
  return (
    <CustomSelector
      className={className}
      header="Ämne"
      list={substances}
      selectedValue={selectedSubstance.id}
      handleChange={handleChange}
    />
  );
};
SubstanceSelector.propTypes = {
  className: PropTypes.string,
  substances: PropTypes.array,
  selectedSubstance: PropTypes.object,
  setSelectedSubstance: PropTypes.func,
};
export default SubstanceSelector;
