export function appendSubmissionParam(url) {
  return appendExistingParam(url, 'submission');
}

function appendExistingParam(url, key) {
  const queryParams = getQueryParams(window.location.search);
  return appendQueryParam(url, key, queryParams[key]);
}

export function appendQueryParam(url, name, value) {
  if (value === undefined) return url;
  return appendQueryString(url, `${name}=${value}`);
}

export function appendQueryObject(url, queryObject) {
  const queryString = getQueryStringFromQueryObject(queryObject);
  return appendQueryString(url, queryString);
}

export function getQueryStringFromQueryObject(queryObject) {
  return Object.keys(queryObject)
    .map((key) => `${key}=${queryObject[key]}`)
    .join('&');
}

export function appendQueryString(url, queryString) {
  if (queryString === undefined) return url;
  url += url.indexOf('?') < 0 ? '?' : '';
  url += url[url.length - 1] === '?' ? '' : '&';
  return `${url}${queryString}`;
}

export function getQueryParams(url) {
  const paramArr = url.slice(url.indexOf('?') + 1).split('&');
  const params = {};
  paramArr.map((param) => {
    const [key, val] = param.split('=');
    params[key] = val;
  });
  return params;
}
