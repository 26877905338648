import Leaflet from 'leaflet';
import proj4leaflet from 'proj4leaflet';
Leaflet.extend(proj4leaflet);

const projections = {
  EPSG3006: {
    crs: new Leaflet.Proj.CRS(
      'EPSG:3006',
      '+proj=utm +zone=33 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs',
      {
        resolutions: [2304, 1024, 512, 256, 128, 64, 32, 16, 8],
        origin: [0, 0],
      }
    ),
    center: [62.2, 16.5],
    zoom: 0,
    minZoom: 0,
    maxZoom: 8,
    continuousWorld: true,
  },
};

export const MAP_OPTIONS = {
  zoomControl: false,
  attributionControl: false,
  ...projections.EPSG3006,
};

export const BACKGROUND_LAYER_OPTIONS = {
  url: `${process.env.REACT_APP_MAPSERVICE_URL}/mom.map`,
  subdomains: '1234',
  layers: 'osm:openstreetmap-background_world_900913_SMHI',
  format: 'image/png',
  zIndex: 1,
};

export const COUNTIES_LAYER_OPTIONS = {
  url: `${process.env.REACT_APP_MAPSERVICE_URL}/mom.map`,
  subdomains: '1234',
  layers: 'gsd:counties_sweden_3021_::2011-03-16',
  format: 'image/png',
  transparent: true,
  pane: 'overlayPane',
  zIndex: 1001,
};

export const MUNICIPAL_LAYER_OPTIONS = {
  url: `${process.env.REACT_APP_MAPSERVICE_URL}/mom.map`,
  subdomains: '1234',
  layers: 'gsd:municipalities_sweden_3021_::2010-05-25',
  format: 'image/png',
  transparent: true,
  pane: 'overlayPane',
  zIndex: 2002,
};

export const CITIES_LAYER_OPTIONS = {
  url: `${process.env.REACT_APP_MAPSERVICE_URL}/mapproxy/service`,
  subdomains: '1234',
  layers: 'osm-aurora-standard-base-map-light-labels',
  format: 'image/png',
  transparent: true,
  pane: 'overlayPane',
  zIndex: 50000,
};

export const PNG_LAYER_OPTIONS = {
  opacity: 0.7,
  zIndex: 10,
};
