import React from 'react';
import PropTypes from 'prop-types';
import Control from 'react-leaflet-control';
import { Button } from '@aurora/widgets-react';
import { AddOutline, RemoveOutline } from '@aurora/icons-react';

const MapZoom = ({ onZoomIn, onZoomOut }) => {
  return (
    <>
      <Control id="map-zoom" position="topleft">
        <Button
          variant={Button.variants.quaternary}
          title="Zooma in"
          onClick={onZoomIn}
          icon={<AddOutline />}
          style={{ marginLeft: '0px' }}
          size="s"
          iconOnly
        />
        <Button
          variant={Button.variants.quaternary}
          title="Zooma ut"
          onClick={onZoomOut}
          icon={<RemoveOutline />}
          style={{ marginLeft: '0px' }}
          size="s"
          iconOnly
        />
      </Control>
    </>
  );
};

MapZoom.propTypes = {
  onZoomIn: PropTypes.func,
  onZoomOut: PropTypes.func,
};

export default MapZoom;
