import React from 'react';
import PropTypes from 'prop-types';
import CustomSelector from './CustomSelector';
import {
  addSubstanceKeyValues,
  sortSubstances,
  addSpecialSubstanceKeyValues,
  setDefaultSelectionIfSelectedSubstanceIsMissing,
} from './utils';

const SubSectorSelector = ({
  className,
  filter,
  setSubstances,
  selectedSector,
  selectedSubSector,
  setSelectedSubSector,
  subSectors,
  selectedSubstance,
  setSelectedSubstance,
}) => {
  const fillSubstances = (selectedValue) => {
    if (selectedSector.id === 0 && selectedValue.id === 0) return;
    const substanceKeyValues = [];
    const subSectors = getSelectedSubSectors(selectedValue);
    subSectors.forEach((subSector) => {
      addSubstanceKeyValues(substanceKeyValues, subSector.substances);
    });
    setSubstanceKeyValues(substanceKeyValues);
    setDefaultSelectionIfSelectedSubstanceIsMissing(
      substanceKeyValues,
      selectedSubstance,
      setSelectedSubstance
    );
  };

  function getSelectedSubSectors(selectedValue) {
    if (selectedSector.id !== 0 && selectedValue.id === 0) {
      const headSector = getSelectedHeadSector(selectedSector.id.toString());
      return headSector.subsectors;
    } else {
      const headSector = getSelectedHeadSector(selectedValue.sectorId.toString());
      return [getSelectedSubSector(headSector.subsectors, selectedValue)];
    }
  }

  function getSelectedSubSector(subSectors, selectedValue) {
    return subSectors.find((item) => item.code === selectedValue.id.toString());
  }

  function getSelectedHeadSector(headSectorId) {
    return filter.find((item) => item.code === headSectorId);
  }

  function setSubstanceKeyValues(substanceKeyValues) {
    addSpecialSubstanceKeyValues(substanceKeyValues);
    sortSubstances(substanceKeyValues);
    setSubstances(substanceKeyValues);
  }

  const handleChange = (e) => {
    const newValue = e.target.value;
    const choice = subSectors.find((obj) => {
      return obj.key === newValue.toString();
    });
    const subSector = choice
      ? { id: newValue, name: choice.value, sectorId: getSectorId(newValue) }
      : { id: 0, name: 'Alla', sectorId: selectedSector.id };
    setSelectedSubSector(subSector);
    fillSubstances(subSector);
  };

  const getSectorId = (value) => {
    return String(value).charAt(0);
  };

  return (
    <CustomSelector
      className={className}
      header="Undersektor"
      list={subSectors}
      selectedValue={selectedSubSector.id}
      handleChange={handleChange}
    />
  );
};
SubSectorSelector.propTypes = {
  className: PropTypes.string,
  filter: PropTypes.array,
  sectorName: PropTypes.string,
  setSubstances: PropTypes.func,
  selectedSector: PropTypes.object,
  selectedSubSector: PropTypes.object,
  setSelectedSubSector: PropTypes.func,
  subSectors: PropTypes.array,
  selectedSubstance: PropTypes.object,
  setSelectedSubstance: PropTypes.func,
};
export default SubSectorSelector;
