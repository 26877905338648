import { appendSubmissionParam } from './utils';

const ERROR_MESSAGE = 'Ett fel uppstod att hämta data, vänligen försök igen senare.';

const END_POINT = `${process.env.REACT_APP_GEOFORD_API_URL}/api/getfilteroptions/`;

export const getFilterOptions = async () => {
  try {
    const response = await fetch(appendSubmissionParam(END_POINT));
    const json = await response.json();
    if (!response.ok) {
      return {
        error: ERROR_MESSAGE,
      };
    }

    return json;
  } catch (e) {
    return { error: ERROR_MESSAGE };
  }
};
