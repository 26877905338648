import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';

const html2canvasOptions = {
  logging: false,
  useCORS: true,
  scale: 1,

  // Prevents the content in the generated image from being pushed
  // downwards. Something that could happen if the image is generated
  // when the page has been scrolled down some. To work properly this
  // requires the style "position: fixed".
  scrollY: 0,
  scrollX: 0,
};

export function generatePdfFromMap(fileName) {
  const pageMap = document.getElementsByClassName('map-container')[0];
  const pdfContainer = document.getElementsByClassName('map-pdf-container')[0];
  const pdfMap = getClonedMapElement(pageMap);
  appendClonedMapElement(pdfContainer, pdfMap);

  return generateCanvas(pdfContainer, html2canvasOptions)
    .then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      generatePdfFromImage(imgData, getFileName(fileName));
    })
    .then(() => removeClonedMapElement(pdfContainer, pdfMap));
}

// By generating a canvas ourself and disabling image smoothing
// we avoid the bug where html2canvas does not consider the
// image-rendering CSS attributes.
// https://github.com/niklasvh/html2canvas/issues/2850
function generateCanvas(element, options) {
  const canvas = document.createElement('canvas');
  (canvas.width = element.offsetWidth * devicePixelRatio),
    (canvas.height = element.offsetHeight * devicePixelRatio);
  const ctx = canvas.getContext('2d');
  ctx.webkitImageSmoothingEnabled = false;
  ctx.mozImageSmoothingEnabled = false;
  ctx.msImageSmoothingEnabled = false;
  ctx.imageSmoothingEnabled = false;
  return html2canvas(element, { canvas, ...options });
}

function getFileName(argumentFileName) {
  const attributeFileName = document
    .getElementsByClassName('map-pdf-filename')[0]
    .innerHTML.toString();
  return sanitizeFileName(
    argumentFileName !== undefined ? argumentFileName : attributeFileName
  );
}

function sanitizeFileName(fileName) {
  const regex = /([^a-z0-9 .,_+\-()]+)/gi;
  return fileName.replace(regex, '');
}

function generatePdfFromImage(imgData, fileName) {
  const pdf = new jsPDF('l', 'px', 'a4');
  pdf.addImage(imgData, 'PNG', 0, 0);
  pdf.save(fileName === undefined || fileName === '' ? 'download.pdf' : fileName);
}

function appendClonedMapElement(pdfContainer, clonedMap) {
  pdfContainer.children[0].appendChild(clonedMap);
}

function removeClonedMapElement(pdfContainer, clonedMap) {
  pdfContainer.children[0].removeChild(clonedMap);
}

function getClonedMapElement(originalMap) {
  const clonedMap = originalMap.cloneNode(originalMap);
  removeLegend(clonedMap);
  removeZoomControl(clonedMap);
  removeMapLayer(clonedMap);
  return clonedMap;
}

function removeLegend(map) {
  removeElementsByClass(map, 'leaflet-control');
}

function removeZoomControl(map) {
  removeElementsByClass(map, 'leaflet-interactive');
}

function removeMapLayer(map) {
  removeElementsByClass(map, 'leaflet-layer-background');
}

function removeElementsByClass(element, className) {
  const elements = element.getElementsByClassName(className);
  while (elements.length > 0) elements[0].parentNode.removeChild(elements[0]);
}
