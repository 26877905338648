import { appendQueryString, appendQueryParam, appendSubmissionParam } from './utils';

const END_POINT = `${process.env.REACT_APP_GEOFORD_API_URL}/api/getdatafile/`;

export function getDataFile(queryString, filetype) {
  const url = appendSubmissionParam(
    appendQueryParam(appendQueryString(END_POINT, queryString), 'filetype', filetype)
  );
  downloadFileByElementClick(url);
}

function downloadFileByElementClick(url) {
  const downloadElement = document.createElement('a');
  downloadElement.setAttribute('href', url);
  downloadElement.style.display = 'none';
  document.body.appendChild(downloadElement);
  downloadElement.click();
  document.body.removeChild(downloadElement);
}
