import React, { useState, useEffect, useRef } from 'react';
import { Map, WMSTileLayer } from 'react-leaflet';
import PropTypes from 'prop-types';
import * as L from 'leaflet';
import DownloadButtons from '../DownloadButtons';
import Legend from '../Legend';
import MapDownload from '../MapDownload';
import MapLegend from '../MapLegend';
import MapPdf from '../MapPdf';
import { generatePdfFromMap } from '../MapPdf/MapPdfUtils';
import MapZoom from '../MapZoom';
import Proj4ImageOverlay from '../Proj4ImageOverlay';
import countiesGeojson from '../../assets/countiesGeojson.json';
import municipalitiesGeojson from '../../assets/municipalitiesGeojson.json';
import { getLayerInfo } from '../../api/getlayerinfo';
import { getDataFile } from '../../api/getdatafile';
import { getQueryStringFromQueryObject } from '../../api/utils';

import 'leaflet/dist/leaflet.css';
import './Map.css';

import {
  MAP_OPTIONS,
  BACKGROUND_LAYER_OPTIONS,
  COUNTIES_LAYER_OPTIONS,
  CITIES_LAYER_OPTIONS,
  MUNICIPAL_LAYER_OPTIONS,
  PNG_LAYER_OPTIONS,
} from './mapUtils';

export const AirMap = ({
  prevSelectedCounty,
  prevSelectedMunicipality,
  selectedCounty,
  selectedMunicipality,
  selectedSector,
  selectedSubSector,
  selectedSubstance,
  selectedYear,
}) => {
  const map = useRef(null);
  const [pngLayerMetadata, setPngLayerMetadata] = useState({});
  const [renderDataLayer, setRenderDataLayer] = useState(false);
  const [handleFileQueryString, setHandleFileQueryString] = useState(null);

  const handlePdfSave = () => {
    generatePdfFromMap();
  };

  const handleTiffSave = (queryString) => {
    getDataFile(queryString, 'tiff');
  };

  const handleAsciiSave = (queryString) => {
    getDataFile(queryString, 'ascii');
  };

  useEffect(() => {
    function getSelectedFiltersAsQueryString() {
      return getQueryStringFromQueryObject({
        county: selectedCounty.id,
        municipality: selectedMunicipality.id,
        sector: selectedSector.id,
        subsector: selectedSubSector.id,
        substance: selectedSubstance.id,
        year: selectedYear,
      });
    }

    function wasAnySectorSelected() {
      return selectedSector.id !== 0 || selectedSubSector.id !== 0;
    }

    const initData = async () => {
      if (wasAnySectorSelected()) {
        try {
          const queryString = getSelectedFiltersAsQueryString();
          const response = await getLayerInfo(queryString);
          if (response && !response.error) {
            setPngLayerMetadata(response);
            setHandleFileQueryString(queryString);
            setRenderDataLayer(true);
            return;
          }
        } catch (e) {
          () => {};
        }
      }
      setRenderDataLayer(false);
    };
    initData();
  }, [
    selectedCounty,
    selectedMunicipality,
    selectedSubSector,
    selectedSector,
    selectedSubstance,
    selectedYear,
  ]);

  useEffect(() => {
    const initMaps = async () => {
      if (map && map.current) {
        const changeCounty = prevSelectedCounty !== selectedCounty;
        const changeMunicipality = prevSelectedMunicipality !== selectedMunicipality;
        const allCountiesSelected = selectedCounty.id === 0;
        const allMunicipalitiesSelected = selectedMunicipality.id === 0;

        if (
          (changeCounty || changeMunicipality) &&
          allCountiesSelected &&
          allMunicipalitiesSelected
        ) {
          zoomToCenter();
        } else if ((changeCounty || changeMunicipality) && allMunicipalitiesSelected) {
          zoomToFeature(countiesGeojson, selectedCounty.id);
        } else if (changeMunicipality) {
          zoomToFeature(municipalitiesGeojson, selectedMunicipality.id);
        }
      }
    };

    const zoomToCenter = () => {
      map.current.leafletElement.flyTo(MAP_OPTIONS.center, MAP_OPTIONS.zoom);
    };

    const zoomToFeature = (geoJson, selectedFeatureId) => {
      let feature = getGeoJsonFeature(geoJson, selectedFeatureId);
      fitBoundsToFeature(feature);
      return feature;
    };

    const getGeoJsonFeature = (geoJson, selectedFeatureId) => {
      return geoJson.features.find(
        (feature) => feature.properties.id === selectedFeatureId.toString()
      );
    };

    // Zoom in to the selected county/municipal by setting the outer
    // bounds to the outer bounds of the county/municipal. Due to the
    // large size of some municipals the zoom level could get too low.
    // So low that the municipal boundaries are not shown. One example
    // is Strömsund in Jämtland.
    const fitBoundsToFeature = (feature) => {
      map.current.leafletElement.fitBounds(getFeatureMinMaxBounds(feature));
    };

    // This will not give the correct bounds when the map shown is in
    // SWEFREF99 projection. But I would say it works good enough so
    // for now I suggest we ignore this.
    const getFeatureMinMaxBounds = (feature) => {
      const coordinates = getCoordinates(feature.geometry.coordinates);
      const lats = coordinates.map((coordinate) => coordinate[1]);
      const lngs = coordinates.map((coordinate) => coordinate[0]);
      const minLat = Math.min(...lats);
      const maxLat = Math.max(...lats);
      const minLng = Math.min(...lngs);
      const maxLng = Math.max(...lngs);
      const maxSW = L.latLng(minLat, minLng);
      const maxNE = L.latLng(maxLat, maxLng);
      return L.latLngBounds(maxSW, maxNE);
    };

    const getCoordinates = (coordinates) => {
      if (coordinates.length > 0 && isCoordinate(coordinates[0])) return coordinates;
      else if (coordinates.length === 1) return getCoordinates(coordinates[0]);
      else {
        let concats = [];
        coordinates.forEach((coordinate) => {
          concats.push(...getCoordinates(coordinate));
        });
        return concats;
      }
    };

    const isCoordinate = (array) => {
      return array.length === 2 && !isNaN(array[0]) && !isNaN(array[1]);
    };

    initMaps();
  }, [
    prevSelectedCounty,
    prevSelectedMunicipality,
    selectedCounty,
    selectedMunicipality,
  ]);

  function getPdfFileName() {
    let name = '';
    if (selectedCounty.id === 0 && selectedMunicipality.id === 0) name += 'Sverige_';
    if (selectedCounty.id !== 0 && selectedMunicipality.id === 0)
      name += `${selectedCounty.name}_`;
    if (selectedMunicipality.id !== 0) name += `${selectedMunicipality.name}_`;
    if (selectedSector.id !== 0) name += `${selectedSector.name}_`;
    if (selectedSubSector.id !== 0) name += `${selectedSubSector.name}_`;
    name += `${selectedSubstance.id}_${selectedYear}.pdf`;
    name = replaceCharacters(name);
    return name;
  }

  function replaceCharacters(name) {
    return replace(name, [
      ['s län', ''],
      [' län', ''],
      ['å', 'a'],
      ['ä', 'a'],
      ['ö', 'o'],
      ['Å', 'A'],
      ['Ä', 'A'],
      ['Ö', 'O'],
      ['Ö', 'O'],
      [' <', ''],
      [' >', ''],
    ]);
  }

  function replace(value, pairs) {
    pairs.forEach((pair) => {
      value = value.replace(pair[0], pair[1]);
    });
    return value;
  }

  function getPdfHeaderText() {
    return `${selectedSubstance.name} - ${selectedYear}`;
  }

  function getPdfSubHeaderText() {
    return `Län: ${selectedCounty.name}
Kommun: ${selectedMunicipality.name}
Huvudsektor: ${selectedSector.name}
Undersektor: ${selectedSubSector.name}

${getTotalEmissionText()}

Enhet: ${getUnitText()}`;
  }

  function getUnitText() {
    const superscript2 = '\u00b2';
    return `(ton/år)/km${superscript2}`;
  }

  function getTotalEmissionText() {
    const newline = '\u000a';
    let totalEmissions = addThousandSeparators(
      round(pngLayerMetadata.emission_total, 0)
    );
    return `Totala emissioner:${newline}${totalEmissions} ton/år`;
  }

  function getLegendHeaderText() {
    const newline = '\u000a';
    return `${getTotalEmissionText()}${newline}${newline}${getUnitText()}`;
  }

  function round(value, decimals) {
    const roundingFactor = Math.pow(10, decimals);
    return Math.round(value * roundingFactor) / roundingFactor;
  }

  function addThousandSeparators(value) {
    return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');
  }

  const zoomIn = () => {
    if (map && map.current) {
      map.current.leafletElement.zoomIn();
    }
  };

  const zoomOut = () => {
    if (map && map.current) {
      map.current.leafletElement.zoomOut();
    }
  };

  function getImageOverlayUrl(overlay) {
    return `${process.env.REACT_APP_GEOFORD_API_URL}/api/getoverlay/${overlay}`;
  }

  function getBoundsFromBbox(bbox) {
    return L.bounds([bbox.west, bbox.south], [bbox.east, bbox.north]);
  }

  return (
    <div>
      {!renderDataLayer && (
        <h4>Välj en huvud- eller undersektor för att få upp data i kartan.</h4>
      )}
      <div className="map-container">
        <Map className="map" {...MAP_OPTIONS} ref={map}>
          <WMSTileLayer
            {...BACKGROUND_LAYER_OPTIONS}
            className="leaflet-layer-background"
          />
          {renderDataLayer && (
            <Proj4ImageOverlay
              {...PNG_LAYER_OPTIONS}
              map={map.current.leafletElement}
              url={getImageOverlayUrl(pngLayerMetadata.overlay)}
              bounds={getBoundsFromBbox(pngLayerMetadata.bbox)}
              className="leaflet-image-overlay"
            />
          )}
          <WMSTileLayer
            {...MUNICIPAL_LAYER_OPTIONS}
            className="leaflet-layer-municipal"
          />
          <WMSTileLayer
            {...COUNTIES_LAYER_OPTIONS}
            className="leaflet-layer-counties"
          />
          <WMSTileLayer {...CITIES_LAYER_OPTIONS} className="leaflet-layer-cities" />
          <MapZoom onZoomIn={zoomIn} onZoomOut={zoomOut} />
          <MapDownload enabled={renderDataLayer} iconText="Ladda ner" hideText="Dölj">
            <div className="download-container">
              <DownloadButtons
                queryString={handleFileQueryString}
                downloadPdf={handlePdfSave}
                downloadTiff={handleTiffSave}
                downloadAscii={handleAsciiSave}
              />
            </div>
          </MapDownload>
          <MapLegend
            enabled={renderDataLayer}
            headerText={getLegendHeaderText()}
            iconText="Legend"
            hideText="Dölj"
          >
            <div className="legend-container">
              <Legend legendArray={pngLayerMetadata.legend_array} />
            </div>
          </MapLegend>
        </Map>
      </div>
      <MapPdf
        headerText={getPdfHeaderText()}
        subHeaderText={getPdfSubHeaderText()}
        legendData={pngLayerMetadata.legend_array}
        fileName={getPdfFileName()}
      />
    </div>
  );
};

AirMap.propTypes = {
  prevSelectedCounty: PropTypes.object,
  prevSelectedMunicipality: PropTypes.object,
  selectedCounty: PropTypes.object,
  selectedMunicipality: PropTypes.object,
  selectedSector: PropTypes.object,
  selectedSubSector: PropTypes.object,
  selectedSubstance: PropTypes.object,
  selectedYear: PropTypes.number,
};

export default AirMap;
