import React from 'react';
import PropTypes from 'prop-types';
import { List, ListItem } from '@aurora/widgets-react';
import './Legend.css';

export const Legend = ({ legendArray }) => {
  if (!legendArray) {
    return null;
  }

  // Get the lowest value down on the legend list.
  const legendArrayReverse = legendArray.slice().reverse();

  const colorArray = [
    'rgb(204,51,51)', // röd
    'rgb(255,51,51)',
    'rgb(255,102,51)',
    'rgb(255,153,51)',
    'rgb(255,204,51)',
    'rgb(255,255,51)',
    'rgb(204,204,51)',
    'rgb(153,204,51)',
    'rgb(153,204,153)', // grön
  ];

  return (
    <div>
      <List>
        {legendArrayReverse.map((item, index) => (
          <ListItem
            key={`legend-item-${index}`}
            className="legend-list-item"
            content={parseFloat(item).toExponential(3)}
            iconLeft={
              <span
                style={{
                  height: 15,
                  width: 15,
                  background: colorArray[index],
                }}
              />
            }
          ></ListItem>
        ))}
      </List>
    </div>
  );
};

Legend.propTypes = {
  legendArray: PropTypes.array,
};

export default Legend;
