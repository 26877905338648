import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import countiesGeojson from '../../assets/countiesGeojson.json';
import CustomSelector from './CustomSelector';
import { sortKeyValuePairs, getAllKeyValuePair } from './utils';

const CountySelector = ({
  className,
  selectedCounty,
  setSelectedCounty,
  setSelectedMunicipality,
  prevSelectedCounty,
}) => {
  const [counties, setCounties] = useState([]);

  // Fill counties on first load only.
  useEffect(() => {
    fillCounties();

    function fillCounties() {
      const keyValues = [];
      const countiesGeoObject = JSON.parse(JSON.stringify(countiesGeojson));
      addCountyKeyValues(keyValues, countiesGeoObject.features);
      sortKeyValuePairs(keyValues);
      keyValues.unshift(getAllKeyValuePair());
      setCounties(keyValues);
    }

    function addCountyKeyValues(countyKeyValues, counties) {
      counties.forEach((county) => {
        countyKeyValues.push({
          key: Number(county.properties.id),
          value: county.properties.lan_namn,
        });
      });
    }
  }, []);

  const handleChange = (e) => {
    const selectedId = Number(e.target.value);
    if (prevSelectedCounty.id !== selectedId)
      setSelectedMunicipality({ id: 0, name: 'Alla' });
    const selectedItem = counties.find((obj) => {
      return obj.key === selectedId;
    });
    setSelectedCounty({ id: selectedItem.key, name: selectedItem.value });
  };
  return (
    <CustomSelector
      className={className}
      header="Län"
      list={counties}
      selectedValue={selectedCounty.id}
      handleChange={handleChange}
    />
  );
};
CountySelector.propTypes = {
  className: PropTypes.string,
  selectedCounty: PropTypes.object,
  setSelectedCounty: PropTypes.func,
  setSelectedMunicipality: PropTypes.func,
  prevSelectedCounty: PropTypes.object,
};
export default CountySelector;
