import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CustomSelector from './CustomSelector';
import {
  addSubstanceKeyValues,
  sortKeyValuePairs,
  sortSubstances,
  addSpecialSubstanceKeyValues,
  getAllKeyValuePair,
  setDefaultSelectionIfSelectedSubstanceIsMissing,
} from './utils';

const SectorSelector = ({
  className,
  filter,
  selectedSubSector,
  setSubsectors,
  selectedSector,
  setSelectedSector,
  setSubstances,
  setSelectedSubSector,
  selectedSubstance,
  setSelectedSubstance,
}) => {
  const [sectors, setSectors] = useState([getAllKeyValuePair()]);

  useEffect(() => {
    const keyValues = [];
    addHeadSectorKeyValues(keyValues, filter);
    sortKeyValuePairs(keyValues);
    keyValues.unshift(getAllKeyValuePair());
    setSectors(keyValues);
  }, [filter]);

  useEffect(() => {
    const handleAll = () => {
      const subSectorKeyValues = [];
      const substanceKeyValues = [];
      filter.forEach((sector) => {
        const subSectors = sector.subsectors;
        addSubSectorKeyValues(subSectorKeyValues, subSectors);
        subSectors.forEach((subSector) => {
          addSubstanceKeyValues(substanceKeyValues, subSector.substances);
        });
      });
      setSubSectorKeyValues(subSectorKeyValues);
      if (selectedSubSector.id === 0) {
        setSubstanceKeyValues(substanceKeyValues);
      }
    };

    if (selectedSector.id === 0) {
      handleAll();
    }
    // eslint-disable-next-line
  }, [filter, selectedSector.id, selectedSubSector.id, setSubsectors, setSubstances]);

  const fillSubSectorSelector = (currentSector) => {
    if (currentSector.id === 0) return;
    const subSectorKeyValues = [];
    const substanceKeyValues = [];

    const headSector = getSelectedHeadSector(currentSector.id.toString());
    const subSectors = headSector.subsectors;
    addSubSectorKeyValues(subSectorKeyValues, subSectors);
    setSubSectorKeyValues(subSectorKeyValues);
    if (isSelectedSubSectorStillAvailable(subSectorKeyValues) === false) {
      setSelectedSubSector(getAllIdNamePair());
    }

    subSectors.forEach((subSector) => {
      addSubstanceKeyValues(substanceKeyValues, subSector.substances);
    });
    setSubstanceKeyValues(substanceKeyValues);
    setDefaultSelectionIfSelectedSubstanceIsMissing(
      substanceKeyValues,
      selectedSubstance,
      setSelectedSubstance
    );
  };

  function getSelectedHeadSector(headSectorId) {
    return filter.find((item) => parseInt(item.code) === parseInt(headSectorId));
  }

  function addHeadSectorKeyValues(headSectorKeyValues, headSectors) {
    headSectors.forEach((item) => {
      headSectorKeyValues.push({ key: item.code, value: item.sector });
    });
  }

  function addSubSectorKeyValues(subSectorKeyValues, subSectors) {
    subSectors.forEach((item) => {
      subSectorKeyValues.push({ key: item.code, value: item.subsector });
    });
  }

  function setSubSectorKeyValues(subSectorKeyValues) {
    sortKeyValuePairs(subSectorKeyValues);
    subSectorKeyValues.unshift(getAllKeyValuePair());
    setSubsectors(subSectorKeyValues);
  }

  function setSubstanceKeyValues(substanceKeyValues) {
    addSpecialSubstanceKeyValues(substanceKeyValues);
    sortSubstances(substanceKeyValues);
    setSubstances(substanceKeyValues);
  }

  function getAllIdNamePair() {
    return {
      id: 0,
      name: 'Alla',
    };
  }

  function isSelectedSubSectorStillAvailable(subSectorKeyValues) {
    return subSectorKeyValues.some((x) => Number(x.key) === selectedSubSector.id);
  }

  const handleChange = (e) => {
    const newValue = e.target.value;
    const currentSector = sectors.find((obj) => {
      return obj.key === newValue;
    });
    const sector = currentSector
      ? { id: newValue, name: currentSector.value }
      : getAllIdNamePair();
    setSelectedSector(sector);
    if (newValue !== 0) {
      fillSubSectorSelector(sector);
    }
  };
  return (
    <CustomSelector
      className={className}
      header="Huvudsektor"
      list={sectors}
      selectedValue={selectedSector.id}
      handleChange={handleChange}
    />
  );
};
SectorSelector.propTypes = {
  className: PropTypes.string,
  filter: PropTypes.array,
  selectedSubSector: PropTypes.object,
  setSubsectors: PropTypes.func,
  selectedSector: PropTypes.object,
  setSelectedSector: PropTypes.func,
  setSubstances: PropTypes.func,
  setSelectedSubSector: PropTypes.func,
  selectedSubstance: PropTypes.object,
  setSelectedSubstance: PropTypes.func,
};
export default SectorSelector;
