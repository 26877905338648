import { appendQueryString, appendSubmissionParam } from './utils';

const ERROR_MESSAGE =
  'Ett fel uppstod när lagerinformationen skulle hämtas, vänligen försök igen senare.';

const END_POINT = `${process.env.REACT_APP_GEOFORD_API_URL}/api/getlayerinfo/`;

export const getLayerInfo = async (queryString) => {
  try {
    const url = appendSubmissionParam(appendQueryString(END_POINT, queryString));
    const response = await fetch(url);
    const json = await response.json();

    if (!response.ok) {
      return {
        error: ERROR_MESSAGE,
      };
    }

    return json;
  } catch (e) {
    return { error: ERROR_MESSAGE };
  }
};
