import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CustomSelector from './CustomSelector';
import municipalitiesGeojson from '../../assets/municipalitiesGeojson.json';
import { sortKeyValuePairs, getAllKeyValuePair } from './utils';

const MunicipalitySelector = ({
  className,
  selectedCounty,
  selectedMunicipality,
  setSelectedMunicipality,
}) => {
  const [municipalities, setMunicipalities] = useState([]);

  // Fill municipals whenever the selected county is changed.
  useEffect(() => {
    fillMunicipals();

    function fillMunicipals() {
      const municipalsKeyValues = [];
      const municipalsGeoObject = JSON.parse(JSON.stringify(municipalitiesGeojson));
      addMunicipalKeyValues(municipalsKeyValues, municipalsGeoObject.features);
      sortKeyValuePairs(municipalsKeyValues);
      municipalsKeyValues.unshift(getAllKeyValuePair());
      setMunicipalities(municipalsKeyValues);
    }

    function addMunicipalKeyValues(municipalKeyValues, municipals) {
      municipals.forEach((municipal) => {
        if (
          selectedCounty.id === 0 ||
          Number(municipal.properties.lan_code) === selectedCounty.id
        ) {
          municipalKeyValues.push({
            key: Number(municipal.properties.id),
            value: municipal.properties.kom_namn,
          });
        }
      });
    }
  }, [selectedCounty, setMunicipalities]);

  const handleChange = (e) => {
    const selectedId = Number(e.target.value);
    const selectedItem = municipalities.find((obj) => {
      return obj.key === selectedId;
    });
    setSelectedMunicipality({ id: selectedItem.key, name: selectedItem.value });
  };

  return (
    <CustomSelector
      className={className}
      header="Kommun"
      list={municipalities}
      selectedValue={selectedMunicipality.id}
      handleChange={handleChange}
    />
  );
};
MunicipalitySelector.propTypes = {
  className: PropTypes.string,
  selectedCounty: PropTypes.object,
  selectedMunicipality: PropTypes.object,
  setSelectedMunicipality: PropTypes.func,
};
export default MunicipalitySelector;
