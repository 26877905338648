import React from 'react';
import PropTypes from 'prop-types';
import Legend from '../Legend';
import './MapPdf.css';

const MapPdf = ({ headerText, subHeaderText, legendData, fileName }) => {
  return (
    <div className="map-pdf-container">
      <div className="map-pdf-container-left"></div>
      <div className="map-pdf-container-right">
        <div className="map-pdf-header">{headerText}</div>
        <div className="map-pdf-sub-header">{subHeaderText}</div>
        <div className="map-pdf-legend">
          <Legend legendArray={legendData} />
        </div>
      </div>
      <div className="map-pdf-filename">{fileName}</div>
    </div>
  );
};

MapPdf.propTypes = {
  headerText: PropTypes.string,
  subHeaderText: PropTypes.string,
  legendData: PropTypes.array,
  fileName: PropTypes.string,
};

export default MapPdf;
