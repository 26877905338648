import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Control from 'react-leaflet-control';
import { areChildrenEqual } from '../../utils/areChildrenEqual';
import './MapLegend.css';
import { HideOutline, ListOutline } from '@aurora/icons-react';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import { Button } from '@aurora/widgets-react';

const MapLegend = ({ enabled, iconText, hideText, headerText, children }) => {
  const [open, setOpen] = useState(false);

  const toggleControl = () => {
    if (!enabled) return;
    setOpen((open) => !open);
  };

  const control = useRef(null);
  useOutsideClick(control, toggleControl);

  return (
    <>
      <Control id="map-legend" position="bottomright" className="map-legend--control">
        <Button
          onClick={toggleControl}
          variant={Button.variants.secondary}
          disabled={!enabled}
        >
          {open ? (
            <>
              <HideOutline />
              {hideText}
            </>
          ) : (
            <>
              <ListOutline />
              {iconText}
            </>
          )}
        </Button>
        {open && (
          <div ref={control} className="map-legend-control--content">
            <div>
              {headerText !== null && headerText !== '' && (
                <span className="header">{headerText}</span>
              )}
              {enabled && children}
            </div>
          </div>
        )}
      </Control>
    </>
  );
};

MapLegend.propTypes = {
  enabled: PropTypes.bool,
  iconText: PropTypes.string,
  hideText: PropTypes.string,
  headerText: PropTypes.string,
  children: PropTypes.node,
};

function areEqual(prevProps, nextProps) {
  return (
    prevProps.enabled === nextProps.enabled &&
    prevProps.headerText === nextProps.headerText &&
    areChildrenEqual(prevProps.children, nextProps.children)
  );
}

export default React.memo(MapLegend, areEqual);
